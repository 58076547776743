
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { VXETable, VxeFormEvents, VxeFormInstance, VxeGridInstance, VxeToolbarInstance } from 'vxe-table'
import request from '@/plugin/request';
import dayjs from 'dayjs'
export default defineComponent({
    components: {

    },
    setup () {

        const searchForm = reactive({
            data: {
                month: dayjs().format('YYYY-MM')
            },
            items: [
                {
                    field: 'month',
                    title: '月份',
                    span: 8,
                    itemRender: {
                        name: 'ElDatePicker',
                        props: {
                            size: 'mini',
                            type: 'month',
                            format: 'YYYY-MM'
                        }
                    }
                },
                {
                    align: 'center',
                    span: 24,
                    itemRender: {
                        name: 'ElButtons',
                        children: [
                            { props: { type: 'primary', content: '查询', nativeType: 'submit', size: 'mini' } },
                            { props: { content: '重置', nativeType: 'reset', size: 'mini' } }
                        ]
                    }
                }
            ],
            rules: {}
        })

        const searchEvent: VxeFormEvents.Submit = () => {
            VXETable.modal.message({ content: '查询事件', status: 'info' })
        }

        const resetEvent: VxeFormEvents.Reset = () => {
            VXETable.modal.message({ content: '重置事件', status: 'info' })
        }


        const gridRef = ref({} as VxeGridInstance)

        const grid = reactive({
            column: [
                // { type: 'checkbox', width: 100 },
                { type: 'seq', width: 50 },
                { field: 'date', title: '日期', width: 120 },
                { field: 'image', title: '图片', slots: { default: 'image' } },
                { field: 'operation', title: '操作', slots: { default: 'operation' }, width: 200 },

            ],
            data: []
        })

        const gridAdd = (data?: any) => {
            const $form = scheduleFormRef.value
            if ($form.data) {
                $form.reset();
                scheduleForm.data.image = ''
            }

            if (data) {
                scheduleForm.data.date = data.date
                scheduleForm.data.image = data.image
            }

            scheduleForm.visible = true
        }

        const gridRemove = (row: any) => {
            request('/notify/schedule', row, { method: 'DELETE', loading: true, message: true }).then(response => {
                handleQuery()
            })
        }

        const scheduleFormRef = ref({} as VxeFormInstance)

        let scheduleForm = reactive({

            visible: false,
            data: {
                month: '',
                date: '',
                image: ''
            },
            items: [
                {
                    field: 'date',
                    title: '日期',
                    span: 24,
                    itemRender: {
                        name: 'ElDatePicker',
                        props: {
                            size: 'mini',
                            type: 'date',
                            format: 'YYYY-MM-DD',
                            valueFormat: 'YYYY-MM-DD',
                        }
                    }
                },
                { field: 'image', title: '图片', span: 24, slots: { default: 'image' } },
                {
                    align: 'center',
                    span: 24,
                    itemRender: {
                        name: 'ElButtons',
                        children: [
                            { props: { type: 'success', content: '保存', nativeType: 'submit', size: 'mini' } },
                        ]
                    }
                }
            ],
            rules: {}
        })


        const scheduleFormSubmit = () => {
            const $form = scheduleFormRef.value
            if ($form.data) {
                request('/notify/schedule', $form.data, { method: 'POST', loading: true, message: true }).then(response => {
                    handleQuery().then(() => { scheduleForm.visible = false })
                })
            }
        }

        const scheduleFormUpload = async () => {
            const { file } = await VXETable.readFile()
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (ev: any) => { //文件读取成功完成时触发
                scheduleForm.data.image = ev.target.result; //获得文件读取成功后的DataURL,也就是base64编码
            }

        }


        const handleQuery = async () => {

            request('/notify/schedule', { month: searchForm.data.month }, { method: 'GET' }).then(response => {

                const { result } = response

                grid.data = result

            })
        }

        onMounted(() => {

            handleQuery()


        })



        return {
            searchForm,
            searchEvent,
            resetEvent,
            grid,
            gridRef,
            gridAdd,
            gridRemove,
            scheduleForm,
            scheduleFormRef,
            scheduleFormUpload,
            scheduleFormSubmit
        };
    },

});
