<template>
    <div>
        <vxe-form :rules="searchForm.rules" :data="searchForm.data" :items="searchForm.items" @submit="searchEvent" @reset="resetEvent">

        </vxe-form>

        <vxe-toolbar ref="xToolbar" size="mini">
            <template #buttons>
                <vxe-button content="提交" status="success" @click="groupFormOpen()"></vxe-button>
                <!-- <vxe-button content="删除" status="danger"></vxe-button> -->
            </template>
        </vxe-toolbar>

        <div>
            <vxe-grid size="mini" ref="xGrid" round auto-resize align="center" :data="grid.data" :columns="grid.column">
                <template #operation="{ row }">
                    <!-- {{row}} -->
                    <vxe-button content="修改" status="warning" @click="groupFormOpen(row)"></vxe-button>
                    <vxe-button content="删除" status="danger" @click="gridRemove(row)"></vxe-button>
                </template>
            </vxe-grid>
        </div>

        <vxe-modal v-model="groupForm.visible" title="分组维护" width="600" resize>
            <template #default>
                <vxe-form ref="groupFormRef" :data="groupForm.data" :rules="groupForm.rule" title-align="right" title-width="60" :items="groupForm.items" @submit="groupFormSubmit">

                </vxe-form>
            </template>
        </vxe-modal>

    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { VXETable, VxeFormEvents, VxeFormInstance, VxeGridInstance, VxeToolbarInstance } from 'vxe-table'
import request from '@/plugin/request';
export default defineComponent({
    components: {

    },
    setup () {
        const xGrid = ref({} as VxeGridInstance)
        const groupFormRef = ref({} as VxeFormInstance)

        const searchForm = reactive({
            data: {
                name: ''
            },
            items: [
                {
                    field: 'name',
                    title: '组名',
                    span: 8,
                    itemRender: {
                        name: 'ElInput',
                        props: {
                            size: 'mini',
                            placeholder: '请输入组名'
                        }
                    }
                },
                {
                    align: 'center',
                    span: 24,
                    itemRender: {
                        name: 'ElButtons',
                        children: [
                            { props: { type: 'primary', content: '查询', nativeType: 'submit', size: 'mini' } },
                            { props: { content: '重置', nativeType: 'reset', size: 'mini' } }
                        ]
                    }
                }
            ],
            rules: {}
        })

        const searchEvent: VxeFormEvents.Submit = () => {
            VXETable.modal.message({ content: '查询事件', status: 'info' })
        }

        const resetEvent: VxeFormEvents.Reset = () => {
            VXETable.modal.message({ content: '重置事件', status: 'info' })
        }

        const grid = reactive({
            column: [
                // { type: 'checkbox', width: 100 },
                { type: 'seq', width: 50 },
                { field: 'name', title: '分组', width: 120 },
                { field: 'memberName', title: '成员' },
                { field: 'operation', title: '操作', slots: { default: 'operation' }, width: 200 },

            ],
            data: []
        })

        const gridRemove = (row: any) => {
            request('/notify/group', row, { method: 'DELETE', loading: true, message: true }).then(response => {
                handleQuery()
            })
        }

        const handleQuery = async () => {

            request('/notify/group', {}, { method: 'GET' }).then(response => {

                const { result } = response

                grid.data = result

            })
        }

        let groupForm = reactive({

            visible: false,
            data: {
                name: '',
                member: []
            },
            items: [
                {
                    field: 'name',
                    title: '组名',
                    span: 24,
                    itemRender: {
                        name: 'ElInput',
                        props: {
                            size: 'mini',
                            placeholder: '请输入组名',
                            disabled: false
                        }
                    }
                },
                {
                    field: 'member',
                    title: '成员',
                    span: 24,
                    itemRender: {
                        name: 'ElSelect',
                        optionGroups: [],
                        props: {
                            filterable: true,
                            size: 'mini',
                            placeholder: '请输入组名',
                            multiple: true,
                            disabled: false
                        }
                    }
                },
                {
                    align: 'center',
                    span: 24,
                    itemRender: {
                        name: 'ElButtons',
                        children: [
                            { props: { type: 'success', content: '新增', nativeType: 'submit', size: 'mini' } },
                        ]
                    }
                }
            ],
            rules: {}
        })

        const groupFormOpen = (data?: any) => {
            const $form = groupFormRef.value
            if ($form.data) { $form.reset() }

            if (data) {
                groupForm.data.name = data.name
                groupForm.data.member = data.member

                if (groupForm.items[0].itemRender && groupForm.items[0].itemRender.props) {
                    groupForm.items[0].itemRender.props.disabled = true
                }

            } else {

                if (groupForm.items[0].itemRender && groupForm.items[0].itemRender.props) {
                    groupForm.items[0].itemRender.props.disabled = false
                }

            }



            groupForm.visible = true

        }

        const groupFormSubmit = () => {
            const $form = groupFormRef.value
            if ($form.data) {
                request('/notify/group', $form.data, { method: 'POST', loading: true, message: true }).then(response => {
                    handleQuery().then(() => { groupForm.visible = false })
                })
            }
        }

        onMounted(() => {

            request('/notify/group/employee', {}, { method: 'GET' }).then(response => {

                const { result } = response

                groupForm.items[1].itemRender.optionGroups = result

            })

            handleQuery()


        })

        return {
            searchForm,
            searchEvent,
            resetEvent,
            grid,
            gridRemove,
            groupForm,
            groupFormRef,
            groupFormOpen,
            groupFormSubmit
        };
    },

});
</script>


<style scoped>
</style>