<template>
    <div>

        <el-space direction="vertical" style="width:100%" alignment="">

            <el-card class="box-card" size="mini">
                <template #header>
                    <div class="card-header">
                        <span>测试机器人</span>
                        <div>
                            <el-button class="button" type="success" size="mini" @click="handleSubmit('test')">保存</el-button>
                        </div>

                    </div>
                </template>
                <vxe-form ref="testFormRef" :rules="testForm.rules" :data="testForm.data" :items="testForm.items">

                </vxe-form>
            </el-card>

            <el-card class="box-card" size="mini">
                <template #header>
                    <div class="card-header">
                        <span>通知机器人</span>
                        <div>
                            <el-button class="button" type="success" size="mini" @click="handleSubmit('notify')">保存</el-button>
                            <el-button class="button" type="primary" size="mini" @click="handleRobotStart('notify')">启动</el-button>
                            <el-button class="button" type="primary" size="mini" @click="handleRobotTest('notify')">测试</el-button>
                        </div>

                    </div>
                </template>
                <vxe-form ref="notifyFormRef" :rules="notifyForm.rules" :data="notifyForm.data" :items="notifyForm.items">

                </vxe-form>
            </el-card>

            <el-card class="box-card" size="mini">
                <template #header>
                    <div class="card-header">
                        <span>定时机器人</span>
                        <div>
                            <el-button class="button" type="success" size="mini" @click="handleSubmit('schedule')">保存</el-button>
                            <el-button class="button" type="primary" size="mini" @click="handleRobotTest('schedule')">测试</el-button>
                        </div>
                    </div>
                </template>
                <vxe-form ref="scheduleFormRef" :rules="scheduleForm.rules" :data="scheduleForm.data" :items="scheduleForm.items">

                </vxe-form>
            </el-card>

        </el-space>

    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import { VxeTableInstance, VxeTableEvents, VxeToolbarInstance, VxeFormInstance } from 'vxe-table'
import request from '@/plugin/request';
import linq from 'linq'
export default defineComponent({
    components: {

    },
    setup () {

        const items = [
            {
                title: '左侧',
                span: 16,
                children: [
                    {
                        field: 'url',
                        title: '地址',
                        span: 24,
                        itemRender: {
                            name: 'ElInput',
                            props: {
                                type: "textarea",
                                size: 'mini',
                                placeholder: '请输入名称'
                            }
                        }
                    },

                ]
            },
            {
                title: '右侧',
                span: 8,
                children: [
                    {
                        field: 'time', title: '时间',
                        span: 24,
                        itemRender: {
                            name: 'ElTimeSelect',
                            props: {
                                size: 'mini',
                                placeholder: '请输入昵称',
                                start: '08:30',
                                step: '00:05',
                                end: '18:30'
                            }
                        }
                    }
                ]
            }
        ]

        const rules = {
            url: [
                { required: true, message: '地址是必填项' }
            ],
            time: [
                { required: true, message: '时间是必填项' }
            ],
        }

        const testFormRef = ref({} as VxeFormInstance)

        const testForm = reactive({
            data: {
                type: 'test',
                url: '',
                time: '00:00',
            },
            items: items,
            rules
        })

        const notifyFormRef = ref({} as VxeFormInstance)

        const notifyForm = reactive({
            data: {
                type: 'notify',
                url: '',
                time: '',
            },
            items: items,
            rules
        })


        const scheduleFormRef = ref({} as VxeFormInstance)

        const scheduleForm = reactive({
            data: {
                type: 'schedule',
                url: '',
                time: '',
            },
            items: items,
            rules
        })


        const handleSubmit = (formName: string) => {

            let $form: VxeFormInstance | undefined = undefined

            switch (formName) {
                case 'test': $form = testFormRef.value; break;
                case 'notify': $form = notifyFormRef.value; break;
                case 'schedule': $form = scheduleFormRef.value; break;
            }

            $form?.validate((valid) => {
                if (valid) { return }
                request('/notify/robot', $form?.data, { method: 'POST', loading: true, message: true }).then(response => {
                    console.log(response.result)
                })
            })


        }

        const handleRobotStart = (type: string) => {

            ElMessageBox.prompt('请输入启动日期', '', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /\d{4}-\d{2}-\d{2}/,
                inputErrorMessage: '日期格式不正确',
                inputValue: dayjs().format('YYYY-MM-DD')
            })
                .then(({ value }) => {
                    request('/notify/robot/start', { type, date: value }, { method: 'POST', message: true }).then(response => { })
                })
                .catch(() => { ElMessage({ type: 'info', message: '取消输入', }) });
        }

        const handleRobotTest = (type: string) => {


            ElMessageBox.prompt('请输入启动日期', '', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /\d{4}-\d{2}-\d{2}/,
                inputErrorMessage: '日期格式不正确',
                inputValue: dayjs().format('YYYY-MM-DD')
            })
                .then(({ value }) => {
                    request('/notify/robot/test', { type, date: value }, { method: 'POST', message: true })
                })
                .catch(() => { ElMessage({ type: 'info', message: '取消输入', }) });

        }


        nextTick(() => {

        })

        onMounted(() => {

            request('/notify/robot', {}, { method: 'GET' }).then(response => {

                const { result } = response
                if (result) {

                    testForm.data.url = linq.from(result).where((f: any) => f.type === 'test').select((f: any) => f.url).firstOrDefault()

                    testForm.data.time = linq.from(result).where((f: any) => f.type === 'test').select((f: any) => f.time).firstOrDefault()

                    scheduleForm.data.url = linq.from(result).where((f: any) => f.type === 'schedule').select((f: any) => f.url).firstOrDefault()

                    scheduleForm.data.time = linq.from(result).where((f: any) => f.type === 'schedule').select((f: any) => f.time).firstOrDefault()

                    notifyForm.data.url = linq.from(result).where((f: any) => f.type === 'notify').select((f: any) => f.url).firstOrDefault()

                    notifyForm.data.time = linq.from(result).where((f: any) => f.type === 'notify').select((f: any) => f.time).firstOrDefault()

                }

            })

        })

        return {
            testForm,
            testFormRef,
            notifyForm,
            notifyFormRef,
            scheduleForm,
            scheduleFormRef,
            handleSubmit,
            handleRobotStart,
            handleRobotTest
        };
    },

});
</script>


<style scoped>
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.box-card {
    width: 100%;
}
</style>